import React from 'react';

import {OrgsSection, OrgsTopPositioning} from 'src/components/enterprise/OrgsSection';
import {Box, FireworkContainer, FireworkTextRotator, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {enterprise as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'midnightBlue',
  px: '1.5rem',
  pb: ['3rem', null, null, null, '6rem'],
})``;

const StyledBox: typeof Box = styled(Box).attrs({
  mt: ['3rem', null, null, null, `-${OrgsTopPositioning - 4.5}rem`],
  mx: 'auto',
})``;

const StyledFlex: typeof Flex = styled(Flex).attrs({
  minHeight: [null, null, null, '12.5rem'],
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'center',
  alignItems: 'flex-start',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

export const ForTheSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      <OrgsSection containerMaxWidth={props.containerMaxWidth} />

      <StyledBox maxWidth={props.containerMaxWidth}>
        <StyledFlex>
          <StyledH2 mt={[null, null, null, '0.25rem']} mr="0.75rem" color="periwinkle" fontStyle="italic">
            {copy.for_the.heading}
          </StyledH2>

          <Box maxWidth="37.125rem" mt={['1.5rem', null, null, 0]}>
            <FireworkTextRotator strings={copy.for_the.strings} />
          </Box>
        </StyledFlex>
      </StyledBox>
    </Wrapper>
  );
};
