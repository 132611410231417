import React from 'react';

import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {Icon, Icons} from 'src/svgs';
import {enterprise as copy, logoSection as logoCopy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, P2} = Typography;

// NOTE: used to pad surrounding containers
export const OrgsTopPositioning = 16;

const Wrapper: typeof Box = styled(Box).attrs({
  mx: 'auto',
  mt: ['1.5rem', null, null, null, 0],
  textAlign: 'center',
  transform: [null, null, null, null, 'translateY(-50%)'],
})``;

const InnerContainer: typeof Box = styled(Box).attrs({
  backgroundColor: 'periwinkle',
  mx: 'auto',
  borderRadius: '1.25rem',
  padding: ['3rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  color: 'midnightBlue',
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const LogoContainer = styled(Box).attrs({
  width: ['calc(50% - 1.5rem)'],
  maxWidth: '8.0625rem',
  mx: ['0.75rem', null, null, '2rem'],
  mt: '3rem',
})`
  svg {
    width: 100%;
    margin: 0 auto;
  }
`;

const Logo = styled(Icon).attrs({
  color: 'midnightBlue',
})``;

export const OrgsSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper maxWidth={props.containerMaxWidth}>
      <InnerContainer>
        <StyledH2>{copy.orgs.heading}</StyledH2>

        <Flex flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center">
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.morganStanley} icon={Icons.morganStanley} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.unitedHealthGroup} icon={Icons.unitedHealthGroup} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.lvmh} icon={Icons.lvmh} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.dell} icon={Icons.dell} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.ibm} icon={Icons.ibm} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.loreal} icon={Icons.loreal} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.publicisGroupe} icon={Icons.publicisGroupe} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.accenture} icon={Icons.accenture} />
          </LogoContainer>
          <LogoContainer>
            <Logo aria-label={logoCopy.ariaLabels.helloFresh} icon={Icons.helloFresh} />
          </LogoContainer>
        </Flex>
      </InnerContainer>
    </Wrapper>
  );
};
