import React from 'react';

import {Box} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import styled from 'styled-components/macro';

const {H3, P1, P2, CTA} = Typography;

const PrimaryServiceWrapper: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, 'calc(33% - 1.5rem)'],
  textAlign: 'center',
  mt: ['3rem', null, null, 0],
})`
  &:first-of-type {
    margin-top: 0;
  }

  svg {
    margin: 1.5rem auto 0;
  }
`;

const StyledCTA: typeof CTA = styled(CTA).attrs({
  color: 'neonGreen',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontFamily: fonts.heading,
  letterSpacing: '0.2rem',
  textTransform: 'uppercase',
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  color: 'neonGreen',
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: ['1.5rem'],
})``;

type PrimaryServiceProps = {
  heading: string;
  description: string;
  icon: any;
  key: number;
};

export const PrimaryService: React.FC<PrimaryServiceProps> = ({...props}) => {
  return (
    <PrimaryServiceWrapper>
      <StyledCTA>{props.heading}</StyledCTA>

      <Box>{props.icon}</Box>

      <StyledP2>{props.description}</StyledP2>
    </PrimaryServiceWrapper>
  );
};

const SecondaryServiceWrapper: typeof Box = styled(Box).attrs({
  width: ['100%', null, null, 'calc(50% - 1.5rem)'],
  borderTop: `1px solid`,
  borderColor: 'neonGreen',
  mt: ['3rem', null, null, 0],
  pt: ['1.5rem'],
})`
  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
})``;

const StyledP1: typeof P1 = styled(P1).attrs({
  fontSize: ['1.3125rem', null, null, '1.5rem'],
  lineHeight: ['1.625rem', null, null, '1.8125rem'],
  mt: ['1.5rem'],
})``;

type SecondaryServiceProps = {
  heading: string;
  description: string;
  key: number;
};

export const SecondaryService: React.FC<SecondaryServiceProps> = ({...props}) => {
  return (
    <SecondaryServiceWrapper>
      <StyledH3>{props.heading}</StyledH3>

      <StyledP1>{props.description}</StyledP1>
    </SecondaryServiceWrapper>
  );
};
