import React from 'react';

import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {enterprise as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, H3, P2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  pb: ['3rem', null, null, '4.5rem'],
})`
  .stat:last-child .border {
    display: none;
  }
`;

const StatContainer: typeof Box = styled(Box).attrs({
  className: 'stat',
  pt: ['3rem', null, null, '4.5rem'],
})``;

const StatFlexContainer: typeof Flex = styled(Flex).attrs({
  flexDirection: ['column', null, null, 'row'],
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  mx: 'auto',
  px: '1.5rem',
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledP2: typeof P2 = styled(P2).attrs({
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  mt: ['1.5rem', null, null, '0.75rem'],
})``;

const StyledStat: typeof H2 = styled(H2).attrs({
  color: 'neonGreen',
  fontSize: '9.375rem',
  lineHeight: '9.375rem',
  fontStyle: 'italic',
  mr: [null, null, null, '1.5rem'],
})``;
const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
})``;
const StyledSource: typeof P2 = styled(P2).attrs({
  fontSize: '0.75rem',
  lineHeight: '0.9375rem',
  mt: '0.75rem',
})``;

const Border: typeof Box = styled(Box).attrs({
  className: 'border',
  backgroundColor: 'neonGreen',
  border: 'none',
  width: '100%',
  height: '1px',
  mt: ['3rem', null, null, '4.5rem'],
})``;

export const StatsSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper>
      {copy.stats.stats.map((stat, i) => {
        return (
          <StatContainer key={i}>
            <StatFlexContainer maxWidth={props.containerMaxWidth}>
              <Box maxWidth={[null, null, null, '34.8125rem']}>
                <StyledH2>{stat.heading}</StyledH2>

                <StyledP2>{stat.description}</StyledP2>
              </Box>

              <Box maxWidth={[null, null, null, '35rem']} mt={['6rem', null, null, 0]}>
                <Flex
                  flexDirection={['column', null, null, 'row']}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <StyledStat>{stat.stat}</StyledStat>

                  <Box>
                    <StyledH3>{stat.statDetail}</StyledH3>

                    <StyledSource>{stat.statSource}</StyledSource>
                  </Box>
                </Flex>
              </Box>
            </StatFlexContainer>

            <Border />
          </StatContainer>
        );
      })}
    </Wrapper>
  );
};
