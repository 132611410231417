import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {Box, FireworkContainer, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {ENTERPRISE_INTEREST} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {enterprise as copy} from 'src/utils/copy';
import {MobileOnly, TabletUp} from 'src/utils/responsive';
import styled from 'styled-components/macro';

const {H1, P1} = Typography;

const StyledBox: typeof Box = styled(Box).attrs({
  width: '100%',
  position: 'absolute',
  top: ['3rem', null, null, '2.25rem', '4.5rem', '6rem'],
  px: ['1.5rem', null, null, '5rem'],
})`
  span {
    display: inline-block;
  }

  span:nth-child(2) {
    color: ${themeGet('colors.periwinkle')};
    font-style: italic;
    margin: 0 0.75rem;
  }

  @media screen and (min-width: ${breakpoints[1]}) {
    span {
      display: inline;
    }
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:nth-child(3) {
      display: block;
    }
  }
`;

const StyledH1: typeof H1 = styled(H1).attrs({
  maxWidth: ['21.375rem', null, null, '100%'],
  fontSize: ['3rem', null, null, null, '5rem'],
  lineHeight: ['3rem', null, null, null, '5rem'],
})``;

const StyledP1: typeof P1 = styled(P1).attrs({
  fontSize: ['1.25rem', null, null, null, '1.5rem'],
  lineHeight: ['1.35rem', null, null, null, '1.875rem'],
  mt: '1.5rem',
})``;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'periwinkle',
  width: ['100%', null, null, 'auto'],
  mt: ['1.5rem', null, '4.5rem', '1.5rem'],
})``;

export const Hero: typeof FireworkContainer = ({...props}) => {
  return (
    <Box color="cream" position="relative" overflow="hidden">
      <MobileOnly>
        <Box maxHeight="34rem" overflow="hidden">
          <Image
            src="https://assets.chief.com/lohp/images/enterprise/hero_mobile.png"
            aria-label={copy.hero.ariaLabel}
          />
        </Box>
      </MobileOnly>

      <TabletUp>
        <Image src="https://assets.chief.com/lohp/images/enterprise/hero.png" aria-label={copy.hero.ariaLabel} />
      </TabletUp>

      <StyledBox>
        <StyledH1>
          {copy.hero.headingStrings.map((str, i) => (
            <span key={i}>{str}</span>
          ))}
        </StyledH1>

        <StyledP1>{copy.hero.description}</StyledP1>

        <StyledFireworkCTA
          onClick={(e: MouseEvent) => FireworkCTAClick(e, 'enterprise.hero', ENTERPRISE_INTEREST)}
          to={ENTERPRISE_INTEREST}
        >
          {copy.hero.cta}
        </StyledFireworkCTA>
      </StyledBox>
    </Box>
  );
};
