import React from 'react';

import {themeGet} from '@styled-system/theme-get';
import {FireworkCTAClick} from 'src/analytics';
import {FireworkCTA} from 'src/components/controls';
import {QuotesSection, QuotesTopPositioning} from 'src/components/enterprise/QuotesSection';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {ENTERPRISE_INTEREST} from 'src/routes/paths';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {enterprise as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  backgroundColor: 'darkGreen',
  px: '1.5rem',
  pb: ['3rem', null, null, '6rem'],
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})`
  span:nth-child(2) {
    color: ${themeGet('colors.neonGreen')};
    font-style: italic;
    margin: 0 0.75rem;
  }

  @media screen and (min-width: ${breakpoints[2]}) {
    span:nth-child(2) {
      display: block;
    }
  }
`;

const StyledFireworkCTA: typeof FireworkCTA = styled(FireworkCTA).attrs({
  variant: 'neonGreen',
  width: ['100%', null, null, 'auto'],
  mt: ['3rem', null, null, 0],
})``;

export const InvestSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper backgroundColor="darkGreen">
      <QuotesSection quotes={copy.quotes.quotes} containerMaxWidth={props.containerMaxWidth} />

      <Box mx="auto" mt={QuotesTopPositioning.map(x => `-${x / 2 - 4.5}rem`)} maxWidth={props.containerMaxWidth}>
        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" alignItems="center">
          <StyledH2>
            {copy.invest.headingStrings.map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </StyledH2>

          <Box>
            <StyledFireworkCTA
              onClick={(e: MouseEvent) => FireworkCTAClick(e, 'enterprise.invest', ENTERPRISE_INTEREST)}
              to={ENTERPRISE_INTEREST}
            >
              {copy.invest.cta}
            </StyledFireworkCTA>
          </Box>
        </Flex>
      </Box>
    </Wrapper>
  );
};
