import React, {useState, useEffect, useRef, forwardRef} from 'react';

import {themeGet} from '@styled-system/theme-get';
import {motion, MotionProps, useAnimation} from 'framer-motion';
import {Button} from 'src/components/controls';
import {Box, BoxProps, Flex, Image} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {breakpoints} from 'src/styleguide/defaultTheme';
import {fonts} from 'src/styleguide/fireworkTypographyStyles';
import {fontWeights} from 'src/styleguide/typographyStyles';
import {Icon, Icons} from 'src/svgs';
import {swipePower, wrapIndex} from 'src/utils/slider';
import styled, {keyframes} from 'styled-components/macro';

const {H3, P2} = Typography;

// NOTE: used to pad surrounding containers
export const QuotesTopPositioning = [50, 47, 36, 25];

export type QuotesSectionProps = {quotes: any[]; containerMaxWidth: string; boxProps?: BoxProps};
type QuoteProps = {quote: any; active?: boolean};

const Wrapper: typeof Box = styled(Box).attrs({
  position: 'relative',
  transform: 'translateY(-50%)',
})``;

const Container: typeof Box = styled(Box).attrs({
  backgroundColor: 'cream',
  color: 'darkGreen',
  mx: 'auto',
  borderRadius: '1.25rem',
  padding: ['1.5rem', null, null, '3rem'],
  textAlign: ['center', null, null, 'left'],
})``;

const MotionContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const Quote = styled(motion.div).attrs({
  role: 'tabpanel',
  'aria-roledescription': 'slide',
})`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;

  svg {
    margin: 0 auto;
  }
`;

const NavButton = styled(Button).attrs({
  variant: 'icon',
  className: 'nav-button',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  my: 'auto',
  border: '0',
})`
  :disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

const PageDot = styled.div<{active: boolean}>`
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid ${themeGet('colors.olive')};
  border-radius: 62.5rem;
  background-color: ${props => (props.active ? themeGet('colors.olive') : 'transparent')};
  margin: 0 0.25rem;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BioContainer: typeof Box = styled(Box).attrs({
  order: [1, null, null, 0],
  minWidth: [null, null, null, '15.625rem'],
  mt: ['3rem', null, null, 0],
  mx: ['auto'],
  pb: ['3rem', null, null, 0],
  textAlign: [null, null, null, 'center'],
})``;

const QuoteContainer: typeof Box = styled(Box).attrs({
  order: [0, null, null, 1],
  maxWidth: [null, null, null, '46.9375rem'],
  ml: [null, null, null, '1.5rem'],
})``;

const BioH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
  mt: ['1.5rem'],
})``;

const BioP2: typeof P2 = styled(P2).attrs({
  maxWidth: '15.625rem',
  fontSize: '1.0625rem',
  lineHeight: '1.3125rem',
  fontWeight: fontWeights.bold,
  mt: ['0.75rem'],
})``;

const QuoteH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.375rem', null, null, null, '1.875rem'],
  lineHeight: ['1.375rem', null, null, null, '1.875rem'],
})``;

const QuoteSlide = forwardRef<HTMLDivElement, QuoteProps>(({quote, active}, ref) => {
  return (
    <Quote ref={ref}>
      <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" alignItems="flex-start">
        <BioContainer>
          <Box maxWidth="9.375rem" mx="auto">
            <Image src={quote.pictureUrl} aria-label={`${quote.name} testimonial`} />
          </Box>

          <BioH3>{quote.name}</BioH3>

          <BioP2>{quote.title}</BioP2>
        </BioContainer>

        <QuoteContainer>
          <QuoteH3>{quote.quote}</QuoteH3>
        </QuoteContainer>
      </Flex>
    </Quote>
  );
});

const transition = {type: 'spring', mass: 0.25, bounce: 0, stiffness: 1000, damping: 50};
const dragTransition = {bounceDamping: transition.damping, bounceStiffness: transition.stiffness};

export const QuotesSection: React.FC<QuotesSectionProps> = ({quotes, containerMaxWidth, boxProps}) => {
  const [index, setIndex] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const animation = useAnimation();
  const indexRef = useRef(0);

  const handleNavigateLeft = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index - 1;
      await animation.start({
        x: parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index - 1);
  };

  const handleNavigateRight = async () => {
    await animation.stop();
    if (parentRef.current) {
      indexRef.current = index + 1;
      await animation.start({
        x: -parentRef.current.getBoundingClientRect().width,
        transition,
        pointerEvents: 'none',
      });
    }
    setIndex(index + 1);
  };

  const handleKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        handleNavigateRight();
      } else if (event.key === 'ArrowLeft') {
        handleNavigateLeft();
      }
    },
    [quotes, index, setIndex],
  );

  const handleDragEnd: MotionProps['onDragEnd'] = async (evt, {offset}) => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      const power = swipePower(offset.x, rect.width);
      if (power > 10) {
        await handleNavigateLeft();
      } else if (power < -10) {
        await handleNavigateRight();
      }
    }
  };

  useEffect(() => {
    parentRef.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      parentRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const prevIndex = wrapIndex(0, quotes.length, index - 1);
  const currentIndex = wrapIndex(0, quotes.length, index);
  const nextIndex = wrapIndex(0, quotes.length, index + 1);

  return (
    <Wrapper {...boxProps} aria-roledescription="carousel">
      <Container maxWidth={containerMaxWidth}>
        <Box overflow="hidden">
          <MotionContainer
            key={index}
            drag="x"
            layout
            dragConstraints={{left: 0, right: 0}}
            animate={animation}
            dragMomentum={false}
            dragDirectionLock
            onDragEnd={handleDragEnd}
            whileTap={{cursor: 'grabbing'}}
            dragTransition={dragTransition}
          >
            <QuoteSlide key={prevIndex} quote={quotes[prevIndex]} />
            <QuoteSlide key={index} ref={parentRef} quote={quotes[currentIndex]} active />
            <QuoteSlide key={nextIndex} quote={quotes[nextIndex]} />
          </MotionContainer>
        </Box>

        <Flex
          position="absolute"
          left="0"
          bottom="1.5rem"
          width="100%"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
        >
          <NavButton onClick={handleNavigateLeft}>
            <Box size="1.25rem" transform="rotate(180deg)">
              <Icon icon={Icons.chevronRightLarge} aria-label="Left arrow" color="olive" />
            </Box>
          </NavButton>

          {quotes.map((quote, quoteIndex) => (
            <PageDot key={quote?._key} active={currentIndex === quoteIndex} />
          ))}

          <NavButton onClick={handleNavigateRight}>
            <Box size="1.25rem">
              <Icon icon={Icons.chevronRightLarge} aria-label="Right arrow" color="olive" />
            </Box>
          </NavButton>
        </Flex>
      </Container>
    </Wrapper>
  );
};

QuotesSection.defaultProps = {
  boxProps: {},
};
