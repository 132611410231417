import React from 'react';

import {QuotesTopPositioning} from 'src/components/enterprise/QuotesSection';
import {PrimaryService, SecondaryService} from 'src/components/enterprise/ServicesSection/Services';
import {Box, FireworkContainer, Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/fireworkTypography';
import {enterprise as copy} from 'src/utils/copy';
import styled from 'styled-components/macro';

const {H2, H3, P1} = Typography;

const Wrapper: typeof Box = styled(Box).attrs({
  px: '1.5rem',
  pt: ['3rem', null, null, '4.5rem'],
  pb: QuotesTopPositioning.map(x => `${x / 2 + 4.5}rem`),
})``;

const StyledH2: typeof H2 = styled(H2).attrs({
  fontSize: ['2.875rem', null, null, '3rem'],
  lineHeight: ['3rem', null, null, '3.125rem'],
})``;

const StyledH3: typeof H3 = styled(H3).attrs({
  fontSize: ['1.875rem', null, null, '2rem'],
  lineHeight: ['1.875rem', null, null, '2rem'],
  mt: ['1.5rem'],
})``;

const StyledP1: typeof P1 = styled(P1).attrs({
  fontSize: ['1.3125rem', null, null, '1.5rem'],
  lineHeight: ['1.625rem', null, null, '1.8125rem'],
  mt: ['1.5rem'],
})``;

export const ServicesSection: typeof FireworkContainer = ({...props}) => {
  return (
    <Wrapper backgroundColor="midnightBlue">
      <Box maxWidth={props.containerMaxWidth} mx="auto">
        <StyledH2>{copy.services.heading}</StyledH2>

        <Box width="100%" height="1px" backgroundColor="neonGreen" mt={['1.5rem', null, null, '3rem']} />

        <StyledH3>{copy.services.primary.heading}</StyledH3>

        <StyledP1>{copy.services.primary.description}</StyledP1>

        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" mt={['3rem']}>
          {copy.services.primary.services.map((service, i) => (
            <PrimaryService key={i} {...service} />
          ))}
        </Flex>

        <Flex flexDirection={['column', null, null, 'row']} justifyContent="space-between" mt={['4.5rem']}>
          {copy.services.secondary.services.map((service, i) => (
            <SecondaryService key={i} {...service} />
          ))}
        </Flex>
      </Box>
    </Wrapper>
  );
};
